import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PhotoWall from '../views/PhotoWall.vue'
import WishfulStarrySky from '../views/WishfulStarrySky.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/photowall',
    name: 'photowall',
    component: PhotoWall
  },
  {
    path: '/wishfulstarrysky',
    name: 'wishfulstarrysky',
    component: WishfulStarrySky
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
