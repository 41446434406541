<template>
  <div id="app" :style="bgc">
    <YyyRouter @changeBgc="changeBcg" class="bar-css"/>
    <router-view class="display-area-css"/>
  </div>
</template>

<script>
import YyyRouter from './components/YyyRouter.vue'

export default {
  components: {
    YyyRouter
  },

  data () {
    return {
      bgc: {}
    }
  },

  methods: {
    changeBcg (tabName) {
      if (tabName === 'home') {
        this.bgc = {
          'background-color': '#183059'
        }
      }
      if (tabName === 'wishfulstarrysky') {
        this.bgc = {
          'background-image': 'radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)'
        }
      }
      if (tabName === 'photowall') {
        this.bgc = {
          'background-color': '#183059'
        }
      }
    }
  }
}
</script>

<style scoped>
* {
  outline: none;
}

a {
  color: white
}

a:hover {
  color: none
}

#app {
  width: 100%;
  height: 100%;
  background-color: #183059;
  position: fixed;
}

.bar-css {
  height: 10vh;
  width: 100vw;
  position: fixed;
}

.display-area-css {
  height: 90vh;
  margin-top: 10vh;
}

</style>
