<template>
    <div class="outter">
        <div class="photo-gallery">
            <div v-for="(photo, index) in photoList" :key="photo.photoId" class="photo-wrapper">
                <img :src="photo.photoUrl" :alt="`Photo_${photo.photoId}`" class="thumbnail" @click="showFullImage(index)">
            </div>
        </div>
        <div v-if="selectedPhotoIndex !== null" class="full-image-overlay" @click="closeFullImage">
            <div class="full-image-wrapper">
                <img :src="photoList[selectedPhotoIndex].photoUrl" :alt="`Photo_${photoList[selectedPhotoIndex].photoId}`" class="full-image">
            </div>
            <div ref="navigationRef" class="image-navigation" @keyup.left="showPreviousImage" @keyup.right="showNextImage" tabindex="0">
                <button @click.stop="showPreviousImage" v-show="selectedPhotoIndex !== 0">&lt;</button>
                <button @click.stop="showNextImage" v-show="selectedPhotoIndex !== photoList.length - 1">&gt;</button>
            </div>
        </div>

    </div>
  </template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      photoList: [],
      selectedPhotoIndex: null
    }
  },
  created () {
    if (localStorage.getItem('photoList') &&
    JSON.parse(localStorage.getItem('photoList')).length !== 0) {
      this.photoList = JSON.parse(localStorage.getItem('photoList'))
    } else {
      this.getPhotoList()
    }
  },

  methods: {
    async getPhotoList () {
      const res = await axios.get('/birthdayGift/photo/getPhotoList')
      this.photoList = res.data
      localStorage.setItem('photoList', JSON.stringify(this.photoList))
    },

    showFullImage (index) {
      this.selectedPhotoIndex = index
      this.$nextTick(() => {
        this.$refs.navigationRef.focus()
      })
    },

    closeFullImage () {
      this.selectedPhotoIndex = null
    },

    showPreviousImage () {
      console.log('<')
      if (this.selectedPhotoIndex > 0) {
        this.selectedPhotoIndex--
      }
    },

    showNextImage () {
      console.log('>')
      if (this.selectedPhotoIndex < this.photoList.length - 1) {
        this.selectedPhotoIndex++
      }
    }
  }
}
</script>

<style scoped>
.photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 10px;
    overflow: auto;
    height: 100%;
}

.photo-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
}

.thumbnail {
    position: absolute;
    width: 100%; /** 将元素的宽度设置为父元素的100%。*/
    height: 100%; /** 将元素的宽度设置为父元素的100%。*/
    object-fit: cover; /** 使用 cover 值时，内容将被缩放以填充整个元素，并保持其纵横比，可能会被裁剪。  */
    cursor: pointer; /** 将鼠标指针样式设置为指针形状，表示该元素是可点击的 */
}

.full-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}
.full-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    height: 90%;
    position: relative;
}

.full-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.image-navigation {
    display: flex;
    width: 30%;
    justify-content: space-around;
    outline: none;
}

.image-navigation button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: rgba(30, 22, 54, 0.6);
    box-shadow: rgba(30, 22, 54, 0.4) 0 0px 0px 2px inset;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s ease;
    outline: none;
}

.image-navigation button:hover {
    color: rgba(255, 255, 255, 0.85);
    box-shadow: rgba(30, 22, 54, 0.7) 0 0px 0px 40px inset;
}
</style>
