<template>
  <div class="important-line">
    <a-timeline mode="alternate">
      <a-timeline-item class="timeline-item-yyy" :position="item.position" :color="item.pointColor"  v-for="(item) in importEventList" :key="item.importantEventId">
        <a-icon v-if="item.pointColor === 'green'" slot="dot" type="clock-circle-o" style="font-size: 16px;" />
        <p>{{ item.eventDate }}</p>
        <p>{{ item.eventContent }}</p>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      importEventList: []
    }
  },

  created () {
    if (localStorage.getItem('importEventList') &&
    JSON.parse(localStorage.getItem('importEventList')).length !== 0) {
      this.importEventList = JSON.parse(localStorage.getItem('importEventList'))
    } else {
      this.getImportantEventList()
    }
  },

  methods: {
    async getImportantEventList () {
      const res = await axios.get('/birthdayGift/importantEvent/getImportantEventList')
      this.importEventList = res.data
      localStorage.setItem('importEventList', JSON.stringify(this.importEventList))
    }
  }
}
</script>

<!-- 不能加global，要修改ant组件原生的样式 -->
<style>
p {
  color: white
}

.important-line {
  height: 50%;
  margin: 10px;
  padding: 10px;
  overflow: auto; /* 添加滚动条 */
}

.timeline-item-yyy .ant-timeline-item-head-custom {
  padding: 0;
  border-radius: 50%;
}
</style>
