<template>
  <div class="container">
    <div class="tabs">
      <!-- <input type="radio" id="radio-1" name="tabs"/> -->
      <label class="tab" @click="clickTab('photowall')">
        <router-link :style="selectedTab === 'photowall'?{'color': '#183059'}:{'color': 'white'}" to="/photowall">photowall</router-link>
      </label>
      <label class="tab" @click="clickTab('home')">
        <router-link :style="selectedTab === 'home'?{'color': '#183059'}:{'color': 'white'}" to="/">home</router-link>
      </label>
      <label class="tab" @click="clickTab('wishfulstarrysky')">
        <router-link :style="selectedTab === 'wishfulstarrysky'?{'color': 'black'}:{'color': 'white'}" to="/wishfulstarrysky">wishfulstarrysky</router-link>
      </label>
      <span class="glider" :style="getGliderCss()"></span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectedTab: 'home',
      selectedCss: { color: '#183059' }
    }
  },

  methods: {
    clickTab (tabName) {
      this.$emit('changeBgc', tabName)
      this.selectedTab = tabName
    },

    getGliderCss () {
      const currentTabName = window.location.href.split('/')[3]
      if (currentTabName === 'photowall') {
        this.selectedTab = 'photowall'
        this.$emit('changeBgc', this.selectedTab)
        return { transform: 'translateX(0)' }
      } else if (currentTabName === 'wishfulstarrysky') {
        this.selectedTab = 'wishfulstarrysky'
        this.$emit('changeBgc', this.selectedTab)
        return { transform: 'translateX(200%)' }
      } else {
        this.selectedTab = 'home'
        this.$emit('changeBgc', this.selectedTab)
        return { transform: 'translateX(100%)' }
      }
    }
  }
}
</script>

<style scoped>
.router-content {
  color: white
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs {
  display: flex;
  position: relative;
  color: black;
  padding: 0.6rem;
  border-radius: 99px;
  z-index: 2;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 200px;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}
.glider {
  position: absolute;
  display: flex;
  height: 40px;
  width: 200px;
  background-color: #e6eef9;
  z-index: -1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}
</style>
