<template>
  <div class="home">
    <TogetherTotalTime></TogetherTotalTime>
    <ImportantTimeLine></ImportantTimeLine>
  </div>
</template>

<script>
import TogetherTotalTime from '@/components/TogetherTotalTime.vue'
import ImportantTimeLine from '@/components/ImportantTimeLine.vue'

export default {
  components: {
    TogetherTotalTime,
    ImportantTimeLine
  }
}
</script>

<style scoped>
</style>
