<template>
    <div class="container" ref="containerRef">
      <div class="starry-sky" ref="starrySkyRef">
        <div class="wish-content" v-show="isHover">
            <span>{{ selectedStarContent }}</span>
        </div>
        <p v-for="item in starList" :key="item.wishStarId"
            :class="{'star-style': true, 'is-completed-style': item.isCompleted, 'not-completed-style': !item.isCompleted}"
            :style="{ 'left': item.xposition + 'px', 'top': item.yposition + 'px'}"
            @click="clickStar(item)"
            @mouseover="handleMouseHover(item)"
            @mouseout="handleMouseOut()">
        </p>
      </div>
      <div class="wish-input">
        <input type="text" v-model="newWish" @keydown.enter="addWish" placeholder="输入您的愿望">
        <button @click="addWish()">发送</button>
      </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      clientHeight: '',
      clientWidth: '',
      newWish: '',
      starList: [],
      selectedStarContent: '',
      isHover: false
    }
  },
  created () {
    if (localStorage.getItem('starList') &&
    JSON.parse(localStorage.getItem('starList')).length !== 0) {
      this.starList = JSON.parse(localStorage.getItem('starList'))
    } else {
      this.getWishStarList()
    }
  },

  methods: {
    async getWishStarList () {
      const res = await axios.get('/birthdayGift/wishStar/getWishStarList')
      this.starList = res.data
      localStorage.setItem('starList', JSON.stringify(this.starList))
    },

    async updateWishStar (wishStar) {
      const res = await axios.post('/birthdayGift/wishStar/updateWishStar', wishStar)
      console.log(res.data)
    },

    async addWishStar (wishStar) {
      const res = await axios.post('/birthdayGift/wishStar/addWishStar', wishStar)
      console.log(res.data)
    },

    async addWish () {
      if (this.newWish.trim() === '') {
        this.newWish = ''
        console.log('请输入内容')
        return
      }
      const wishStar = {
        content: this.newWish,
        isCompleted: false,
        creationDate: +new Date(),
        lastUpdateDate: +new Date(),
        xposition: Math.random() * this.clientWidth,
        yposition: Math.random() * this.clientHeight + this.clientHeight / 0.9 / 0.9 * 0.1
      }
      await this.addWishStar(wishStar)
      this.newWish = ''
      this.getWishStarList()
    },

    async clickStar (wishStar) {
      if (wishStar.isCompleted) {
        console.log('the wish has been completed.')
        return
      }
      wishStar.isCompleted = true
      wishStar.lastUpdateDate = +new Date()
      await this.updateWishStar(wishStar)
      this.getWishStarList()
    },

    handleMouseHover (star) {
      this.isHover = true
      this.selectedStarContent = star.content
    },

    handleMouseOut () {
      this.isHover = false
      this.selectedStar = ''
    }
  },

  mounted () {
    // 获取页面高度和宽度
    this.clientHeight = this.$refs.starrySkyRef.clientHeight
    this.clientWidth = this.$refs.starrySkyRef.clientWidth
    console.log(this.clientHeight)
    console.log(this.clientWidth)
  }
}
</script>

<style scoped>
.container {
  height: 90%;
}

.starry-sky {
  height: 90%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 星星 */
.star-style {
    position: absolute;
    margin: 18px;
    width: 6px;
    height: 6px;
    background-clip: content-box;
    border-radius: 50%;
}

.star-style:hover {
    animation-name: none;
    cursor: pointer;
}

.is-completed-style {
    background-color: hsla(72, 87%, 72%, 0.75);
    box-shadow: 0 0 1em 0.3em hsla(72, 87%, 72%, 0.75);
    --color: none;
}

.not-completed-style {
    animation-name: twinkle;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    background-color: #fff;
    --color: #fff;
}

@keyframes twinkle {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 1;
  }
  66% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

.star-style:hover::before {
    content: ''; /* 去除该属性，就没有波纹 */
    position: absolute;
    top: -16px; /* margin - width/2 + border */
    left: -16px;
    width: 36px; /* (top - 星星的宽度/2) * 2 + 星星的宽度 */
    height: 36px;
    border: 2px solid var(--color); /* 动态修改伪元素样式 */
    border-radius: 100%;
    animation-name: boom-circle;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 1.6s;
}

.star-style:hover::after {
    content: ''; /* 去除该属性，就没有波纹 */
    position: absolute;
    top: -16px; /* margin - width/2 + border */
    left: -16px;
    width: 36px; /* (top - 星星的宽度/2) * 2 + 星星的宽度 */
    height: 36px;
    border: 2px solid var(--color); /* 动态修改伪元素样式 */
    border-radius: 100%;
    animation-name: boom-circle;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 1.6s;
    animation-delay: 0.8s;
    opacity: 0;
}

@keyframes boom-circle {
    0% {
        opacity: 1;
    }
    75% {
        opacity: 0;
        transform: scale(3);
    }
    100% {
        opacity: 0;
        transform: scale(3);
    }
}
/* 星星 */

/* 输入框 */
.wish-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
}

.wish-input input {
  padding: 10px;
  height: 70%;
  width: 400px;
  margin-right: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 18px;
  text-align: center;
}

.wish-input button {
  padding: 10px 20px;
  height: 40px;
  background-color: #fff;
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
}
/* 输入框 */

/* 心愿内容 */
.wish-content {
  pointer-events: none; /* 去除hover事件的传递 */
  position: absolute;
  max-width: 70%;
  max-height: 50%;
  color: #fff;
  font-size: 18px;
  place-self: center;
  margin-bottom: 5%;
  margin-right: 5%;
  display: flex;
  overflow: hidden;
}

.wish-content span {
  pointer-events: none; /* 去除hover事件的传递 */
  display: inline-block;
  padding: 25px 50px;
  font-family: momo;
  font-weight: bold;
  letter-spacing: 6px; /* 字间距 */
  font-size: 1.5em;
}

.wish-content::before, .wish-content::after{
  content:"";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #fff;
  animation-name: extendWidth;
  animation-duration: 1.5s;
  animation-timing-function: ease; /* 慢入慢出 */
  animation-fill-mode: forwards; /* 保留动画最后状态 */
}

@keyframes extendWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.wish-content span::before,.wish-content span::after{
  content:"";
  width:2px;
  height:0;
  position: absolute;
  transition: all 0.2s linear;
  background: #fff;
  animation-name: extendHeight;
  animation-duration: 1.5s;
  animation-timing-function: ease; /* 慢入慢出 */
  animation-fill-mode: forwards; /* 保留动画最后状态 */
}

@keyframes extendHeight {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

.wish-content::after{
  right:0;
  bottom: 0;
  transition-duration: 0.4s;
}

.wish-content span::after{
  right:0;
  bottom: 0;
  transition-duration: 0.4s;
}

.wish-content::before{
  left: 0;
  top: 0;
  transition-duration: 0.4s;
}

.wish-content span::before{
  left: 0;
  top: 0;
  transition-duration: 0.4s;
}
/* 心愿内容 */
</style>
